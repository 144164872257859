import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHotelCartList } from "../reduxToolKit/slices/hotelCartListApiSlice";
import axios from "axios";
import { isLoggedIn } from "../utils/LoggedIn";
import { toast } from "react-toastify";
import { images } from "../utils/images";
import { Nav, Tab } from "react-bootstrap";
import { fetchCheckedRoomRate } from "../reduxToolKit/slices/checkRateApiSlice";
import Header2 from "./Header2";
import { postConfirmBooking } from "../reduxToolKit/slices/confirmBooking";
import { Link } from "react-router-dom";
import { fetchConfirmHotelBooking } from "../reduxToolKit/slices/confirmHotelBookingHotelBedApiSlice";
import moment from "moment";

const initialState = {
  passengerDetail: [{ firstName: "", lastName: "", phoneNumber: "", email: "", pancard: "" }],
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  pancard: ""
}

const BookingDetail_Hotel = () => {
  const [iState, updateState] = useState(initialState)
  const [checkedRateList, setCheckedRateList] = useState([])
  const { passengerDetail, firstName, lastName, phoneNumber, email, pancard } = iState
  const [seconds, setSeconds] = useState(60 * 3)
  const dispatch = useDispatch()
  const hoteCartList = useSelector(state => state?.hotelCartList)
  const checkedRoomRateList = useSelector(state => state?.checkedRoomRateList)
  console.log("hoteCartList", hoteCartList.hoteCartList.data)
  console.log("checkedRoomRateList", checkedRoomRateList)

  useEffect(() => {
    dispatch(fetchHotelCartList())
  }, [])
  //=====================================function to empty a cart in 3 min===================================


  const hotelListIdsToRemoveCart = hoteCartList?.hoteCartList?.data?.map((item) => {
    return (item._id)
  })

  console.log("hotelListIdsToRemoveCart", hotelListIdsToRemoveCart)
  //============================================ Check Room Rate============================================================

  const checkRoomRate = (data) => {
    const result = data.map((item) => {
      return {
        rateKey: item.rooms[0].rateKey
      }
    })
    console.log(result)
    return result
  }

  useEffect(() => {
    if (hoteCartList?.hoteCartList?.data?.length) {
      //console.log('time set out ran ', hoteCartList?.hoteCartList?.data?.length)
      const checkedRoomRatekeys = checkRoomRate(hoteCartList?.hoteCartList?.data)
      console.log('checked room rate list', checkedRoomRatekeys)
      dispatch(fetchCheckedRoomRate({ rooms: checkedRoomRatekeys })).then((res) => {
        console.log('Response of rate checked api', res?.payload?.hotel)
        setCheckedRateList(res?.payload?.hotel)
      })
    }
    setTimeout(async () => {
      updateState(initialState)
      try {
        if (hoteCartList?.hoteCartList?.data?.length > 0) {
          const data = {
            cart_ids: hotelListIdsToRemoveCart
          }
          console.log('data of ids to remove hotels from cart', data)
          const result = await axios.post('http://18.217.92.138:4200/api/v1/user/cart/remove-all-hotels', data, {
            headers: {
              "Authorization": isLoggedIn("swtadmin")
            },
          }
          )
          console.log('Result for remove all hotel from cart', result)
          if (result?.data?.status === 200) {
            dispatch(fetchHotelCartList())
            toast.success(result?.data?.message,
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              })
          } else {
            // toast.error('Cannot empty cart', {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 1000,
            // })
          }

        }
      } catch (e) {
        console.log('Error in remove all hotel from cart')
      }

    }, seconds * 1000)

  }, [hoteCartList?.hoteCartList?.data?.length])

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  },)
  //============================================Handle Confirm Booking==========================================

  const leadPassengerArray = [{ firstName, lastName, phoneNumber, email, pancard }]
  const testLeadPassenger = () => {
    if (leadPassengerArray?.[0]?.firstName === "" &&
      leadPassengerArray?.[0]?.lastName === "" &&
      leadPassengerArray?.[0]?.phoneNumber === "" &&
      leadPassengerArray?.[0]?.email === "" &&
      leadPassengerArray?.[0]?.pancard === "") {
      return false
    } else {
      return true
    }
  }
  const testpassengerDetail = () => {
    if (passengerDetail?.[0]?.firstName === "" &&
      passengerDetail?.[0]?.lastName === "" &&
      passengerDetail?.[0]?.phoneNumber === "" &&
      passengerDetail?.[0]?.email === "" &&
      passengerDetail?.[0]?.pancard === "") {
      return false
    } else {
      return true
    }
  }
  const handleValidation = () => {
    // console.log("testLeadPassenger", testLeadPassenger(), "testpassengerDetail", testpassengerDetail())
    if (
      testLeadPassenger() || testpassengerDetail()
    ) {
      return true;
    } else {
      return false;
    }

  }
  const handleConfirmBooking = () => {
    try {
      console.log('length of lead passenger', leadPassengerArray.length, 'passenger detail', passengerDetail.length)

      const roomRateKeys = hoteCartList?.hoteCartList?.data?.map((item1) => {
        return {
          rateKey: item1?.rooms?.[0]?.rateKey
        }
      })
      console.log('Room rate keys', roomRateKeys)
      const data = {
        holder: {
          name: leadPassengerArray?.[0]?.firstName,
          surname: leadPassengerArray?.[0]?.lastName
        },
        "rooms": roomRateKeys
        //  [
        //   {
        //     "rateKey": "20240215|20240217|W|254|124294|DBL.2D|NET 25|RO||1~2~0||N@06~~21c169~-1345724113~N~~~NOR~B7D8B0D14FF14E1170781654978900AAUK00000180000000010202167",
        //     "paxes": [
        //       {
        //         "roomId": 1,
        //         "type": "AD",
        //         "name": "First Adult Name",
        //         "surname": "Surname"
        //       },
        //       {
        //         "roomId": 1,
        //         "type": "AD",
        //         "name": "Second Adult Name",
        //         "surname": "Surname"
        //       }
        //     ]
        //   }
        // ]
        ,
        "clientReference": "IntegrationAgency",
        "remark": "Booking remarks are to be written here.",
        "tolerance": 2
      }
      console.log('handle Validation', handleValidation(), leadPassengerArray, "=====", passengerDetail)
      const isValid = handleValidation()

      if (isValid) {
        dispatch(fetchConfirmHotelBooking(data)).then(async (res) => {
          console.log('Response of confirmed booking ', res);
          if (res.payload) {
            dispatch(postConfirmBooking({ fullResponse: res?.payload?.booking })).then((res) => {
              console.log('Response of confirmation of internal booking api', res)
              if (res.payload.status === 200) {
                toast.success('Hotel Booking confirmed', {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              }
            })

            const removeHotelData = {
              cart_ids: hotelListIdsToRemoveCart,
            };
            console.log('Data of ids to remove hotels from cart', removeHotelData);
            try {
              const result = await axios.post(
                'http://18.217.92.138:4200/api/v1/user/cart/remove-all-hotels',
                removeHotelData,
                {
                  headers: {
                    Authorization: isLoggedIn('swtadmin'),
                  },
                }
              );
              if (result?.data?.status === 200) {
                dispatch(fetchHotelCartList());
                setSeconds(0);
              }
            } catch (error) {
              console.error('Error while removing hotels from cart:', error)
            }
          } else {
            toast.error(res?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
        });
      } else {
        toast.error('Provide detail of Lead Passenger', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        const cartDiv = document.getElementById('customerDetail')
        cartDiv.scrollIntoView({ behavior: 'smooth' })
      }



    } catch (e) {
      console.log('error in confirmation', e)
    }
  }
  const handleChange = (e, i) => {
    console.log('index of array', i)
    const { name, value } = e.target;
    let user = [...passengerDetail]
    user[i] = { ...user[i], [name]: value }
    updateState((prevRes) => ({ ...prevRes, passengerDetail: user }))

    console.log('user', user)
    updateState({
      ...iState,
      passengerDetail: user,
    });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target
    updateState({
      ...iState,
      [name]: value
    })
  }

  const totalAmount = function (hoteCartList) {

    let totalSum = 0
    for (let i = 0; i < hoteCartList?.hoteCartList?.data?.length; i++) {
      totalSum += Number(hoteCartList.hoteCartList.data[i].rooms[0].roomRate)
      // console.log('hoteCartList.hoteCartList.data[i]',hoteCartList.hoteCartList.data[i].rooms[0].roomRate)
    }
    return totalSum.toFixed(2)
  }

  const handleRemoveRoomFromCart = async (roomId) => {
    try {
      const result = await axios.delete(`http://18.217.92.138:4200/api/v1/user/cart/remove-hotel/${roomId}`, {
        headers: {
          "Authorization": isLoggedIn("swtadmin")
        },
      })
      console.log('result from remove list', result)
      if (result?.data.status === 200) {
        toast.success('Room removed sucessfully from cart', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
        dispatch(fetchHotelCartList())
      } else {
        toast.error('Cannot Remove room from cart', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }
    } catch (e) {
      console.log('erron in remove hotel room api', e.message)
    }
  }
  //=====================================================================================================
  console.log('passenger detail', passengerDetail)

  const timer = (params) => {
    const time = Number(params)
    let min = Math.floor(time / 60)
    let second = time % 60
    return `${min}:${second}`
  }

  return (
    <div>
      <Header2 />
      <main id="main" className="ProductMain">
        <section>
          <div className="FilterProductsArea">
            {/* <div class="d-flex my-5">
              <h5 class="" data-toggle="modal" data-target="#BookingConfirmed">BookingConfirmed</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5 class="" data-toggle="modal" data-target="#DeleteModal">Delete Modal</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5 class="" data-toggle="modal" data-target="#CountryModal">Country Modal</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div> */}
            <span className="FilterProductsAreaOverlay">
              {hoteCartList?.hoteCartList?.data?.length > 0 ? `${hoteCartList?.hoteCartList?.data?.length} products added to the cart` : ""}
            </span>
            <div className="row mt-5">
              <div className="col-md-6">

                {
                  hoteCartList?.hoteCartList?.data?.length > 0 ? hoteCartList.hoteCartList.data.map((item) => {
                    return (
                      <div className="BookingHotelDetailsArea">
                        <div className="HotelNameRatings">
                          <figure>
                            <img src={images.hotelImg} />
                          </figure>
                          <h3>{item.hotelName}</h3>
                          <p>
                            <img src={images.location} />
                            {/* 1995 Broadway, New York static */}
                            {item?.destination}
                          </p>
                          <div className="PrpBox">
                            <div className="d-flex align-items-center">
                              <h5>4.0</h5>
                              <div className="RatingStars">
                                <i className="fa-solid fa-star checked" />
                                <i className="fa-solid fa-star checked" />
                                <i className="fa-solid fa-star checked" />
                                <i className="fa-solid fa-star checked" />
                                <i className="fa-solid fa-star" />
                              </div>
                              {/* (<span>Very Good </span>&nbsp; 7 Reviews) */}
                            </div>
                          </div>
                        </div>
                        <div className="BookingHotelChecks">
                          <div>
                            <h5>{item?.checkIn}</h5>
                            <p>Check-In</p>
                          </div>
                          <div>
                            <figure>
                              <img src={images.checkInOUT} />
                            </figure>
                          </div>
                          <div>
                            <h5>{item?.checkOut}</h5>
                            <p>Check-Out</p>
                          </div>
                        </div>
                        <div className="RemarksBox">
                          <h4>Remarks</h4>
                          <p>
                            {item?.description}
                          </p>
                        </div>
                        <div className="RequestAccommodationBox">
                          <h5>Requests for the accommodation</h5>
                          <p>For reference only, Hotelbeds can not guarantee them</p>
                        </div>
                        <div className="RecommendationBox">
                          <aside>
                            <label className="CheckBox">
                              {" "}
                              Room only (hotels)
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Incl. breakfast
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Half board
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Incl. breakfast, lunch and dinner
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Evening meal included
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Evening meal included
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </aside>
                          <aside>
                            <label className="CheckBox">
                              {" "}
                              Room only (hotels)
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Incl. breakfast
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Half board
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Incl. breakfast, lunch and dinner
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                            <label className="CheckBox">
                              {" "}
                              Evening meal included
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </aside>
                        </div>
                        <textarea
                          className="form-control"
                          rows={4}
                          placeholder="Comments"
                          defaultValue={""}
                        />
                        <div className="HotelNamesBox">
                          <div className="HnbLeft">
                            <figure>
                              <img src={images.superiorRoom} />
                            </figure>
                            <h5>{item?.data?.[0]?.rooms?.[0]?.paxes?.[0]?.roomType}</h5>
                            <ul>
                              <li>
                                <span>
                                  <img src={images.cancellationFees} />
                                </span>
                                Product for Packaging
                              </li>
                              <li>
                                <span>
                                  <img src={images.flightSvgRepo} />
                                </span>
                                46% Cancellation fees
                              </li>
                            </ul>
                          </div>
                          <div className="HnbRight">
                            <h6>INR {item?.data?.[0]?.rooms?.[0]?.roomRate}</h6>
                          </div>
                        </div>
                        <div className="RateInformationBox">
                          <h5>Rate Information</h5>
                          <p>
                            Please note that these rates don't allow modifications,
                            therefore you would need to cancel and create a new
                            booking. Cancellation charges apply as per cancellation
                            policy.
                          </p>
                          <div className="Address">
                            <p>
                              {item?.guests} Adults <br />
                              Standard Twin Room with Executive Lounge <br />
                              Breakfast Included <br />
                              <span>Free Cancellation</span> till 25 Aug <br />
                            </p>
                          </div>
                          <p>
                            Date and time are calculated based on local time in the
                            destination. In case of no-show, different fees will
                            apply. Please refer to our T&amp;C.
                          </p>
                        </div>
                      </div>
                    )
                  }) : ""
                }
                <div className="EnterCustomerDetails" id="customerDetail">
                  <h4>Enter Customer Details</h4>
                  <Tab.Container
                    // id="left-tabs-example"
                    defaultActiveKey="LeadPassenger"
                  >
                    <div className="CommonTabscart">
                      <Nav>
                        <Nav.Item className="nav-item">
                          <Nav.Link
                            eventKey="LeadPassenger"
                            //  as={Link}
                            //   to="/payment-management/Payout"
                            className="nav-link"
                          >
                            {/* <img src={images.country_icon} />{" "} */}
                            Enter the lead passenger detail only
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="AllPassenger"
                          //   as={Link}
                          //   to="/payment-management/Transactions"
                          >
                            {/* <img src={images.flightIcon} />{" "} */}
                            {" "}
                            Enter detail of all passenger
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <Tab.Content>
                      <Tab.Pane eventKey="LeadPassenger">
                        {
                          hoteCartList?.hoteCartList?.data?.length > 0 ? <div className="form-group ForName">
                            <div className="BorderNew">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  name="firstName"
                                  value={firstName}
                                  onChange={handleChange2}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="lastName"
                                  value={lastName}
                                  onChange={handleChange2}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Phone Number"
                                  name="phoneNumber"
                                  value={phoneNumber}
                                  onChange={handleChange2}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Email Address"
                                  name="email"
                                  value={email}
                                  onChange={handleChange2}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Pancard Number"
                                  name="pancard"
                                  value={pancard}
                                  onChange={handleChange2}
                                />
                              </div>
                              <p>
                                We’ll call or text you to confirm your number. Standard
                                message and data rates apply. Privacy Policy
                              </p>
                            </div>
                          </div>
                            : ""
                        }
                      </Tab.Pane>
                      <Tab.Pane eventKey="AllPassenger">
                        {
                          Array.from({ length: Number(hoteCartList?.hoteCartList?.data?.[0]?.guests) }).map((item, i) => {
                            if (i === 0) {
                              return (
                                <>
                                  <div className="BorderNew">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        name="firstName"
                                        value={item?.firstName}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={item?.lastName}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        name="phoneNumber"
                                        value={item?.phoneNumber}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email Address"
                                        name="email"
                                        value={item?.email}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Pancard Number"
                                        name="pancard"
                                        value={item?.pancard}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                  </div>
                                </>
                              )
                            } else
                              return (
                                <>
                                  <div className="BorderNew">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        name="firstName"
                                        value={item?.firstName}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={item?.lastName}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                    {/* <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        name="phoneNumber"
                                        value={item?.phoneNumber}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email Address"
                                        name="email"
                                        value={item?.email}
                                        onChange={(e) => handleChange(e, i)}
                                      />
                                    </div> */}
                                  </div>
                                </>
                              )
                          })
                        }
                        {
                          hoteCartList?.hoteCartList?.data?.length > 0 ? <p>
                            We’ll call or text you to confirm your number. Standard
                            message and data rates apply. Privacy Policy
                          </p> : ""
                        }
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
              <div className="col-md-6">
                <div className="PriceBookingDetails">
                  {

                    hoteCartList?.hoteCartList?.data?.length > 0 ? <div className="EmptyCartBox">
                      <p>
                        <img src={images.clock} />
                        Expires in <span>0{timer(seconds)}</span>
                      </p>
                      <span className="EmptyCart">Empty Cart</span>
                    </div> : ""
                  }

                  {
                    hoteCartList?.hoteCartList?.data?.length > 0 ? hoteCartList.hoteCartList.data.map((item, index) => {
                      return (
                        <div className="PbdHotelName">
                          <h6>{item?.hotelName}</h6>
                          <h5>
                            Currency {checkedRateList?.rooms?.[index]?.rates?.[0]?.net}<span onClick={() => handleRemoveRoomFromCart(item._id)}>(Remove)</span>
                          </h5>
                        </div>
                      )
                    }) : ""
                  }
                  {/* <div className="PbdHotelName">
                    <h6>CVK Park Bosphorus Hotel Istanbul</h6>
                    <h5>
                      INR 200<span>(Remove)</span>
                    </h5>
                  </div> */}
                  <label className="CheckBox">
                    I have read <strong>Terms &amp; conditions</strong> and{" "}
                    <strong>Cancellation Policies</strong>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Price Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Amount</td>
                        <td>INR {totalAmount(hoteCartList)}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>INR 0</td>
                      </tr>
                      <tr>
                        <td>Service Fee</td>
                        <td>INR 0</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td>INR {totalAmount(hoteCartList)}</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="PbdHotelName WalletBox">
                    <label className="CheckBox my-0">
                      Wallet
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <h5>
                      INR 200<span>(Add Money)</span>
                    </h5>
                  </div>
                </div>
                <Link
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#BookingConfirmed"
                  className="ConfirmBookigBtn"
                  onClick={handleConfirmBooking}
                // to='/hotelListingHistory'
                >
                  Confirm the Booking
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default BookingDetail_Hotel;
