
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../utils/LoggedIn';


export const fetchProudPartnerList = createAsyncThunk('ProudPartnerList/fetchProudPartnerList', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`http://18.217.92.138:4200/api/v1/user/partner/proud-list`);
    return response.data;
});

const initialState = {
    ProudPartnerList: [],
    status: 'idle',
    error: null,
}
const ProudPartnerSlice = createSlice({
    name: 'ProudPartnerList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProudPartnerList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProudPartnerList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.ProudPartnerList = action.payload;
            })
            .addCase(fetchProudPartnerList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default ProudPartnerSlice.reducer;
