import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { isLoggedIn } from '../../utils/LoggedIn';

// const apiUrl = 'https://api.example.com/cities';

export const cancelPackageBooking = createAsyncThunk('PackageBooking/postPackageBooking', async (payload) => {
  console.log('payload===>',payload)
  const response = await axios.post(`http://18.217.92.138:4200/api/v1/user/booking/status`,payload,{
    headers: {
        "Authorization": isLoggedIn("swtadmin")
      },
  });
  return response.data;
});