import React, { useEffect } from 'react'
import { fetchProudPartnerList } from "../reduxToolKit/slices/proudPartnerApiSlice";
import { useDispatch, useSelector } from 'react-redux';

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const ProudPartner = () => {
    const proudPartner = useSelector((state) => state?.proudPartenrList.ProudPartnerList.data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProudPartnerList())
    }, [])


    const options = {
        rewind: true,
        autoplay: true,
        autoplayTimeout: 5000, // Time between slides
        smartSpeed: 600, // Speed of transition
        dots: false,
        loop: true,
        margin: 10,
        responsive: {
            0: { items: 3 },
            600: { items: 5 },
            1000: { items: 6 }
        }
    };

    const events = {
        // onDragged: function (event) {...},
        // onChanged: function(event) { ...}
    };
    return (
        <div>
            <div
                className="tab-content"
            >
                <div
                //className="tab-pane show active" 
                >
                    <OwlCarousel
                        //className="owl-theme" 
                        options={options} events={events}>
                        {proudPartner?.length > 0 ? (
                            proudPartner.map((item) => (
                                <figure key={item.id}> {/* Assuming each item has a unique `id` */}
                                    <img src={item.image} alt="Proud Partner" width="70" height="80" />
                                </figure>
                            ))
                        ) : (
                            <p>No partners available</p>
                        )}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default ProudPartner