import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";
import { isLoggedIn } from "../../utils/LoggedIn";

// const apiUrl = 'https://api.example.com/cities'

export const packagePriceApi = createAsyncThunk(
    "packagePrice/postpackagePrice",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.post(
            `http://18.217.92.138:4200/api/v1/user/booking/price-according-hotel?packageId=${payload.id}&hotelType=${payload.budget}`,{},
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                }
            }
        );
        return response.data
            ;
    }
);

const packagePriceSlice = createSlice({
    name: "hotelList",
    initialState: {
        packagePriceData: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(packagePriceApi.pending, (state) => {
                state.status = "loading";
            })
            .addCase(packagePriceApi.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.packageList = action.payload;
            })
            .addCase(packagePriceApi.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default packagePriceSlice.reducer;


