import React from 'react'

const Undermaintenance = () => {
    return (
        <div>
            <div

            >
                <img src={require('../assests/images/underMaintenanceImg.png')} style={{ width: '100%' }} />
                {/* <h1>We'll be back soon!</h1>
                <p>Our website is currently undergoing maintenance. Please check back later.</p> */}
            </div>
        </div>
    )
}

export default Undermaintenance