import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { isLoggedIn } from "../../utils/LoggedIn";

// const apiUrl = 'https://api.example.com/cities';

export const commissionApi = createAsyncThunk(
  "addToCart/postAddToCart",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.get(
      `http://18.217.92.138:4200/api/v1/admin/commision/list`,
      {
        headers: {
          "Authorization": isLoggedIn("adminLogin")
        },
        params: payload
      }
    );
    return response.data
      ;
  }
);

const commissionSlice = createSlice({
    name: "hotelList",
    initialState: {
      commissionList: [],
      status: "idle",
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(commissionApi.pending, (state) => {
          state.status = "loading";
        })
        .addCase(commissionApi.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.packageList = action.payload;
        })
        .addCase(commissionApi.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    },
  });

  
  export default commissionSlice.reducer;