import React from 'react'
import { images } from '../utils/images'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <footer>
                <div class="footer-top">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="footer-about">
                                <a href="index.html"><img src={images.logo} /></a>
                                <p>We are a Global DMC helping Travel Agents in India connect with travel service providers worldwide through an online interface. We have been working in the Indian Tourism Industry since 2007. An Indian army veteran established the company as a proprietor firm. The company operated as a travel agency serving domestic, inbound, and outbound tourism and became a Private Limited Company in 2012. The period of 05 years highlighted a gap of dependency of travel agents on the larger travel service providers. This experience leads to the inception of a solution that can empower travel agents across India and make them more independent. To convert the idea into fruitful action, there was a dire need for a seasoned industry practitioner who can understand travel agent's needs next door and implement larger tourism companies' functioning. The year 2020 brings forth an agent of change who converted the idea into a real-time solution empowered with technology for travel agents across India. SWT Club, our Online Global DMC, becomes a reality. A technology solution fueled by a dedicated and experienced team in the domain of B2B travel, we unlock services and products of global service providers for the Indian travel agent next door.</p>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="footer-links">
                                        <h4>Follow Us</h4>
                                        <ul>
                                            <li><a href="javascript:void(0);">
                                                <img src={images.instagramIcon} />Instagram</a>
                                            </li>
                                            <li><a href="javascript:void(0);">
                                                <img src={images.twitterIcon} />Twitter</a>
                                            </li>
                                            <li><a href="javascript:void(0);">
                                                <img src={images.youtubeIcon} />YouTube</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="footer-links">
                                        <h4>Quick Links</h4>
                                        <ul>
                                            <li><a href="javascript:void(0);">Parent Company</a></li>
                                            <li><a href="javascript:void(0);">Story So Far</a></li>
                                            <li><a href="javascript:void(0);">Industry Accredition</a></li>
                                            <li><a href="javascript:void(0);">Our Team</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="footer-contact">
                                        <h4>Contact Us</h4>
                                        <p>ops@swtclub.com</p>
                                        <p>+91 8800212080</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom animate fadeInUp" data-animate="fadeInUp" data-duration="1.5s" data-delay="0.3s" >
                    <ul>
                        <li><Link to='/termsAndConditions'>Terms & Conditions</Link></li>
                        <li><Link to='/privacyPolicy'>Privacy Policy</Link></li>
                    </ul>
                    <p>©2024 SWT All rights reserved</p>
                </div>
            </footer>
        </div>
    )
}

export default Footer