
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../utils/LoggedIn';


export const fetchPartnerList = createAsyncThunk('PartnerList/fetchPartnerList', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`http://18.217.92.138:4200/api/v1/user/partner/trusted-list`);
    return response.data;
});

const initialState = {
    partnerList: [],
    status: 'idle',
    error: null,
}
const partnerSlice = createSlice({
    name: 'partnerList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPartnerList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPartnerList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.partnerList = action.payload;
            })
            .addCase(fetchPartnerList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default partnerSlice.reducer;
