import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoggedIn } from "../../utils/LoggedIn";


export const signUpApi = createAsyncThunk(
    "signUp/postsignUp",
    async (payload,{rejectWithValue}) => {
      try {
        const response = await axios.post(
          "http://18.217.92.138:4200/api/v1/user/signUp",
          payload,
          {
            headers: {
              "Authorization": isLoggedIn("adminLogin")
            },
          }
        );
        return response.data ;
      } catch (error) {
        console.log("error while calling",error);
        return rejectWithValue(error)
      }
    }
  );