import React, { useEffect, useState } from "react";
import "../App.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./Header";
import Footer from "./Footer";
import { privateData } from "../config/apiKey";
import Modal from "react-bootstrap/Modal";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
//================ IMPORT OF IMAGES ==============================//
import bannerVideo from "../assests/video/bannerVideo.mp4";
import { images } from "../utils/images";
import axios from "axios";
import Header2 from "./Header2";
import { isLoggedIn } from "../utils/LoggedIn";
import SwtMap from "./SwtMap";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartnerList } from "../reduxToolKit/slices/partnerListApiSlice";
import { fetchProudPartnerList } from "../reduxToolKit/slices/proudPartnerApiSlice";
import CountUp from 'react-countup';
import { useCountUp } from 'react-countup';
import ReactGA from 'react-ga';
import { fetchTransferList } from "../reduxToolKit/slices/GetTransferListApiSlice";
ReactGA.initialize('G-MY63ZCVDYD');
//=======================================================
const initialState = {
  cityName: "",
  checkIn: "",
  checkOut: "",
  rooms: "",
  guests: "",
};
const Index = () => {
  const [iState, updateState] = useState(initialState);
  const { cityName, checkIn, checkOut, rooms, guests } = iState;
  const [showHeader, setShowHeader] = useState(false)
  const dispatch = useDispatch()
  const partners = useSelector((state) => state?.partnerList.partnerList.data)
  const proudPartner = useSelector((state) => state?.proudPartenrList.ProudPartnerList.data)
  const location = useLocation();
  const navigate = useNavigate()

  console.log('Proud partner', proudPartner)
  //==========show Room================
  const [showRoom, setShowRoom] = useState(false)

  const handleRoom = () => {
    setShowRoom(!showRoom)
  }
  const [showHotelModal, setShowHotelModel] = useState(false);
  const openHotelModal = () => {
    console.log("Open hotel modal clicked");
    console.log("showHotelModal", showHotelModal);
    setShowHotelModel(true);
  };
  const closeHotelModal = () => {
    console.log("showHotelModal", showHotelModal);
    setShowHotelModel(false);
  };
  console.log('show partner', partners)
  const { types } = useLocation()
  console.log('types', types)

  useEffect(() => {
    dispatch(fetchPartnerList())
    dispatch(fetchProudPartnerList())
    dispatch(fetchTransferList())
    // window.scrollTo(0, 0);
  }, [])


  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 1200 && window.screenY < 1210) {
  //       alert("Hello")
  //       // increaseCount()
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    AOS.init();
  }, [])

  // useEffect(()=>{ 
  //   if(isLoggedIn("swtadmin"))
  //   {
  //     console.log("header 1 logged in useEfect");
  //     navigate('/indexLoggedIn')
  //   }   

  //   window.scrollTo({
  //     top: 100,
  //     left: 100,
  //     behavior: "smooth",
  //   });

  // },[])


  // const increaseCount=()=>{
  useCountUp({ ref: 'HotelCounter', end: 300 });
  useCountUp({ ref: 'CountriesCounterr', end: 200 });
  useCountUp({ ref: 'TravelDistributors', end: 71 });
  useCountUp({ ref: 'SourceMarket', end: 150 });
  useCountUp({ ref: 'TransferRoute', end: 24 });
  useCountUp({ ref: 'ActivitiesTickets', end: 18 });
  useCountUp({ ref: 'CarRentalSupplier', end: 5 });
  // }



  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <div>
      <div className="TransBackground">
        <Header />

      </div>
      <main id="main">
        <section className="hero" id="hero">
          <video autoPlay="true" muted="true" loop="true" width="100%">
            <source src={bannerVideo} type="video/mp4" />
          </video>
          <div
            className="hero-content"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1>
              Book Travel Boldly With SWT CLUB <br />
              <span>A Trusted Global B2B travel platform</span>
            </h1>
          </div>
          {
            isLoggedIn("swtadmin") ? "" : <Link to="/signup" className="SignUpBtn">
              Sign Up
            </Link>
          }

        </section>
        <section>
          <div className="OurPartnersArea ConnectWorldArea"
          >
            <div className="ConnectTabsArea">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Packages"
              >
                <div className="CommonTabs">
                  <Nav>
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="FindHotels"
                      //  as={Link}
                      //   to="/payment-management/Payout"
                      >
                        <img src={images.country_icon} />{" "}
                        Find Hotels
                      </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="FindFlight"
                      //   as={Link}
                      //   to="/payment-management/Transactions"
                      >
                        <img src={images.flightIcon} />{" "}
                        {" "}
                        Find Flight
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Packages"
                      //  as={Link}
                      //   to="/payment-management/Payout"
                      >
                        <img src={images.packageIcon} />{" "}
                        Package
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="FindActivities">
                        <img src={images.destination} />{" "}
                        Find Activities
                      </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="FindTransfers"
                      //  as={Link}
                      //   to="/payment-management/Payout"
                      >
                        <img src={images.showTransferBlue} />{" "}
                        Find Transfers
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="FindHotels">
                    <div
                      className="tab-content"
                    >
                      <div className="tab-pane fade show active">
                        <div className="d-flex align-items-center pt-2">
                          <h5>Enter City, Area or Hotel Name</h5>
                        </div>
                        <div className="Filter">
                          <div className="form-group">
                            <div className="CommonInputBox First">
                              <span className="CibLeftOverlay">
                                <img src={images.hotelImg} />
                              </span>
                              <h6>Enter City, Area or Hotel Name</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Hotel Name"
                                value={'Delhi'}
                              />
                              <span className="CibRightOverlay">
                                <img src={images.barIcon} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>Check In</h6>
                              <input
                                type="date"
                                className="form-control-date"
                                value={'2023-12-25'}
                              />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>Check Out</h6>
                              <input
                                type="date"
                                className="form-control-date"
                                value={'2023-12-30'}
                              />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <div 
                            className="InputBox SelectBox"
                            >
                              <span className="StaffIcon">
                                <img src={images.userIcon} />
                              </span>
                              <h6>Guests</h6>
                              <select>
                                <option selected={true}>2</option>
                                <option >2 guests</option>
                                <option>3 guests</option>
                                <option>4 guests</option>
                                <option>5 guests</option>
                              </select>
                              <span className="FilterIcon">
                                <img src={images.chevronDown} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div 
                            className="InputBox SelectBox"
                            >
                              <span className="StaffIcon">
                                <img src={images.userIcon} />
                              </span>
                              <h6>Room</h6>
                              <select>
                                <option selected="">select</option>
                                <option>1 room</option>
                                <option>2 room</option>
                                <option>3 room</option>
                                <option>4 room</option>
                              </select>
                              <span className="FilterIcon">
                                <img src={images.chevronDown} />
                              </span>
                            </div>
                          </div> */}
                          <div className="form-group">
                            <div className="NewSearchBar">
                              <input type="text" className="form-control" placeholder="Details" onClick={handleRoom} />
                              {
                                showRoom ? <div className="NsbarOverlay">
                                  <h4>Room 1</h4>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6>Adults</h6>
                                    <div className="PlusMinusBOx">
                                      <span>
                                        <i className="fa-solid fa-minus" />
                                      </span>
                                      <input type="text" className="form-control" />
                                      <span>
                                        <i className="fa-solid fa-plus" />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6>Children</h6>
                                    <div className="PlusMinusBOx">
                                      <span>
                                        <i className="fa-solid fa-minus" />
                                      </span>
                                      <input type="text" className="form-control" />
                                      <span>
                                        <i className="fa-solid fa-plus" />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="NewSelectBox">
                                    <h6>Children Ages</h6>
                                    <div className="d-flex mt-4 mb-3">
                                      <select className="form-control">
                                        <option selected="">1</option>
                                      </select>
                                      <select className="form-control">
                                        <option>1</option>
                                      </select>
                                      <select className="form-control">
                                        <option selected="">1</option>
                                      </select>
                                      <select className="form-control">
                                        <option selected="">1</option>
                                      </select>
                                    </div>
                                  </div>
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i className="fa-solid fa-plus" />
                                    </span>
                                    Add more rooms
                                  </a>
                                </div> : ""
                              }
                            </div>
                          </div>
                          <div className="form-group">


                            <Link
                              className="ShowHotels"
                              to="/agentLogin"

                            >
                              <img src={images.showHotel} /> Show Hotels
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="FindFlight">
                    <div className="tab-content">
                      <div className="d-flex align-items-center pt-2">
                        <h5>Book Your Flight</h5>
                        <div className="FlightListingRadios ml-4">
                          <div className="form-group">
                            <label className="Radio">
                              {" "}
                              One Way
                              <input
                                type="radio"
                                name="OneWay2"
                                defaultValue="text"
                                defaultChecked=""
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="Radio">
                              {" "}
                              Round Trip
                              <input type="radio" name="OneWay2" defaultValue="file" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text RadioText" style={{ display: "block" }}>
                        <div className="Filter">
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.flightIcon} />
                              </span>
                              <h6>From</h6>
                              <input type="text" className="form-control" placeholder="From" />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.flightIcon} />
                              </span>
                              <h6>To</h6>
                              <input type="text" className="form-control" placeholder="To" />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>Departure Date</h6>
                              <input type="date" className="form-control-date" />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox SelectBox">
                              <span className="StaffIcon">
                                <img src={images.userIcon} />
                              </span>
                              <h6>Guests</h6>
                              <select>
                                <option selected="">select</option>
                                <option>2 guests</option>
                                <option>3 guests</option>
                                <option>4 guests</option>
                                <option>5 guests</option>
                              </select>
                              <span className="FilterIcon">
                                <img src={images.chevronDown} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <Link to="/agentLogin" className="ShowHotels">
                              <img src={images.airplaneWhite} /> Show Flights
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="file RadioText">
                        <div className="Filter">
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.flightIcon} />
                              </span>
                              <h6>From</h6>
                              <input type="text" className="form-control" placeholder="From" />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.flightIcon} />
                              </span>
                              <h6>To</h6>
                              <input type="text" className="form-control" placeholder="To" />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>Departure Date</h6>
                              <input type="date" className="form-control-date" />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>Return Date</h6>
                              <input type="date" className="form-control-date" />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox SelectBox">
                              <span className="StaffIcon">
                                <img src={images.userIcon} />
                              </span>
                              <h6>Guests</h6>
                              <select>
                                <option selected="">select</option>
                                <option>2 guests</option>
                                <option>3 guests</option>
                                <option>4 guests</option>
                                <option>5 guests</option>
                              </select>
                              <span className="FilterIcon">
                                <img src={images.chevronDown} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <Link to='/agentLogin' className="ShowHotels">
                              <img src={images.airplaneWhite} /> Show Flights
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Tab.Pane>
                  <Tab.Pane eventKey="Packages">
                    <div className="tab-content">
                      <div className="tab-pane fade show active">
                        <div className="d-flex align-items-center pt-2">
                          <h5>Enter City, Area or Hotel Name</h5>
                        </div>
                        <div className="Filter">
                          <div className="form-group">
                            <div className="CommonInputBox">
                              <span className="CibLeftOverlay">
                                <img src={images.hotelImg} />
                              </span>
                              <h6>Destination</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Destination"
                              />
                              <span className="CibRightOverlay">
                                <img src={images.barIcon} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="InputBox">
                              <h6>From</h6>
                              <input
                                type="date"
                                className="form-control-date"
                              />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <div className="InputBox">
                              <h6>To</h6>
                              <input
                                type="date"
                                className="form-control-date"
                              />
                              <span className="FilterIcon">
                                <img src={images.calendarBlueImg} />
                              </span>
                            </div>
                          </div> */}
                          <div className="form-group">
                            <div className="InputBox SelectBox">
                              <span className="StaffIcon">
                                <img src={images.userIcon} />
                              </span>
                              <h6>Guests</h6>
                              <select>
                                <option selected="">select</option>
                                <option>2 guests</option>
                                <option>3 guests</option>
                                <option>4 guests</option>
                                <option>5 guests</option>
                              </select>
                              <span className="FilterIcon">
                                <img src={images.chevronDown} />
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <Link className="ShowHotels" to="/agentLogin">
                              <img src={images.showHotel} /> Show Packages
                            </Link>
                            {/* <Link >Show Package</Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="FindActivities">
                    <div className="tab-content">
                      <h5>Book Your Activities</h5>
                      <div className="Filter">
                        <div className="form-group">
                          <div className="CommonInputBox">
                            <span className="CibLeftOverlay">
                              <img src={images.destination} />
                            </span>
                            <h6>Country</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Country Name"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="CommonInputBox">
                            <span className="CibLeftOverlay">
                              <img src={images.destination} />
                            </span>
                            <h6>Destination</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Destination"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="InputBox">
                            <h6>From</h6>
                            <input type="date" className="form-control-date" />
                            <span className="FilterIcon">
                              <img src={images.calendarBlueImg} />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="InputBox">
                            <h6>To</h6>
                            <input type="date" className="form-control-date" />
                            <span className="FilterIcon">
                              <img src={images.calendarBlueImg} />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="InputBox SelectBox">
                            <span className="StaffIcon">
                              <img src={images.userIcon} />
                            </span>
                            <h6>Guests</h6>
                            <select>
                              <option selected="">select</option>
                              <option>2 guests</option>
                              <option>3 guests</option>
                              <option>4 guests</option>
                              <option>5 guests</option>
                            </select>
                            <span className="FilterIcon">
                              <img src={images.chevronDown} />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link to="/agentLogin" className="ShowHotels">
                            <img src={images.destinationWhite} /> Show
                            Activities
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="FindTransfers">
                    <div className="tab-content">
                      <div className="d-flex align-items-center pt-2">
                        <h5>Enter City,Area or Hotel Name</h5>
                        <div className="FlightListingRadios ml-4">
                          <div className="form-group">
                            <label className="Radio">
                              {" "}
                              One Way
                              <input
                                type="radio"
                                name="OneWay2"
                                defaultValue="text"
                                defaultChecked=""
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="Radio">
                              {" "}
                              Round Trip
                              <input
                                type="radio"
                                name="OneWay2"
                                defaultValue="file"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="Filter">
                        <div className="form-group">
                          <div className="CommonInputBox">
                            <span className="CibLeftOverlay">
                              <img src={images.hotelImg} />
                            </span>
                            <h6>From</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="From"
                            />
                            <span className="CibRightOverlay">
                              <img src={images.barIcon} />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="CommonInputBox">
                            <span className="CibLeftOverlay">
                              <img src={images.hotelImg} />
                            </span>
                            <h6>To</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="To"
                            />
                            <span className="CibRightOverlay">
                              <img src={images.barIcon} />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="InputBox">
                            <h6>Departure Date</h6>
                            <input type="date" className="form-control-date" />
                            <span className="FilterIcon">
                              <img src={images.calendarBlueImg} />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="InputBox SelectBox">
                            <span className="StaffIcon">
                              <img src={images.userIcon} />
                            </span>
                            <h6>Guests</h6>
                            <select>
                              <option selected="">select</option>
                              <option>2 guests</option>
                              <option>3 guests</option>
                              <option>4 guests</option>
                              <option>5 guests</option>
                            </select>
                            <span className="FilterIcon">
                              <img src={images.chevronDown} />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link className="ShowHotels" to="/agentLogin">
                            <img src={images.showHotel} /> Show Transfer
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h1>
                Connecting The <span>World’s Travel Trade</span>
              </h1>
              <p>
                We build strong relationships at both ends of the travel chain
                so that we can provide products, <br /> services and solutions
                that help all our client and supplier partners grow
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="BecomeEmpowerArea">
            <div className="row">
              <div className="col-md-6">
                <div className="BecomeEmpowerLeft">
                  <figure
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="0.5s"
                  >
                    <img src={images.agent_1} />
                  </figure>
                  <figure
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="0.7s"
                  >
                    <img src={images.agent_2} />
                  </figure>
                  <figure
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="0.5s"
                  >
                    <img src={images.agent_3} />
                  </figure>
                </div>
              </div>
              <div className="col-md-6">
                <div className="BecomeEmpowerRight">
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Become an <span>Empowered Travel Agent</span>
                  </h1>
                  <ul>
                    <li
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="0.4s"
                    >
                      <i className="fa fa-check" aria-hidden="true" />
                      <span>
                        Access and book millions of Destinations Itineraries and
                        packages
                      </span>
                    </li>
                    <li
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="0.3s"
                    >

                      <span>
                        <i className="fa fa-check" aria-hidden="true" />
                        Unlock Best Deals of International and National Hotels,
                        Airline, Transfers and Sightseeing
                      </span>
                    </li>
                    <li
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="0.2s"
                    >
                      <i className="fa fa-check" aria-hidden="true" />
                      <span>Dedicated Expert Team for Assistance</span>
                    </li>
                    <li
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="0.1s"
                    >
                      <i className="fa fa-check" aria-hidden="true" />
                      <span>Get exceptional Service with Trust</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="HappyClientArea">
            <div className="row">
              <div className="col-md-3">
                <div className="Counter-Box">
                  <h1>
                    <span className="count" data-max="300"></span>
                  </h1>
                  <p>Hotels</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="Counter-Box">
                  <h1>
                    <span className="count" data-max="195"></span>
                  </h1>
                  <p>Destination Countries</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="Counter-Box">
                  <h1>
                    <span className="count" data-max="71"></span>k
                  </h1>
                  <p>Travel Distributors</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="Counter-Box">
                  <h1>
                    <span className="count" data-max="150"></span>
                  </h1>
                  <p>Source Market</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Counter-Box mb-0 text-right">
                  <h1>
                    <span className="count" data-max="24"></span>k
                  </h1>
                  <p>Transfer Route</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Counter-Box mb-0">
                  <h1>
                    <span className="count" data-max="18"></span>k
                  </h1>
                  <p>Activities &amp; Tickets</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Counter-Box mb-0 text-left">
                  <h1>
                    <span className="count" data-max="5"></span>k
                  </h1>
                  <p>Car Rental Supplier</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="FindingYourDreams">
            <div className="row">
              <div className="col-md-4">
                <span className="WhyChooseUs"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >WHY CHOOSE US</span>
              </div>
              <div className="col-md-7">
                <div
                  className="section-title"

                >
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Finding your{" "}
                    <span>
                      dream <br /> destination
                    </span>{" "}
                    is our priority
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    We are a Global DMC helping Travel Agents in India connect
                    with travel service providers worldwide through an online
                    interface. We have been working in Indian Tourism Industry
                    since 2007 . An Indian army veteran established the company
                    as a proprietor firm.
                  </p>
                </div>
              </div>
              <div className="col-md-3 mx-auto">
                <div className="FindingDreamsBox"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-delay="500"
                >
                  <span className="Icon">
                    <img src={images.serviceIcon} />
                  </span>
                  <h5>Service</h5>
                  <p>
                    We promise to offer high quality and diverse services to our
                    partners. We aim to empower our partners with a plethora of
                    travel product options carefully curated by the best travel
                    service provider across the globe.
                  </p>
                </div>
              </div>
              <div className="col-md-3 mx-auto">
                <div className="FindingDreamsBox Second"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-delay="600"
                >
                  <span className="Icon">
                    <img src={images.willingnessIcon} />
                  </span>
                  <h5>Willingness</h5>
                  <p>
                    The willingness to serve our partners with the utmost
                    professionalism, dedication and time is the core focus of
                    SWT. We 24X7 willingly work together to solve the problem of
                    late reverts faced by Travel Agents.
                  </p>
                </div>
              </div>
              <div className="col-md-3 mx-auto">
                <div className="FindingDreamsBox Third"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-delay="700"
                >
                  <span className="Icon">
                    <img src={images.trustIcon} />
                  </span>
                  <h5>Trust</h5>
                  <p>
                    SWT act as a bridge of trust between Travel agents of India
                    and global travel partners. We have a strong team backed by
                    the highest work ethics calibrate with trusted international
                    partners to serve you with the best products at a
                    competitive price.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="OurSolutionsArea">
            <div className="row">
              <div className="col-md-6">
                {" "}
                <div className="OurSolutionsLeft">
                  {/* <span class="HowWeWork animate fadeInUp" data-animate="fadeInUp" data-duration="0.3s" data-delay="0.3s" >HOW WE WORK</span> */}{" "}

                  <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    How <span>Our Solutions</span>
                    <br /> Can Help
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    The solution for those of you who don't want the hassle of
                    ordering travel tickets, with just 3 steps you can travel
                    anywhere you want.
                  </p>
                  {""}
                  {/* <a class="animate fadeInUp" data-animate="fadeInUp" data-duration="2s" data-delay="0.9s" href="javascript:void(0);">Booking now</a> */}{" "}
                </div>
              </div>
              <div className="col-md-6">
                <div className="OurSolutionsRight"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div
                    className="OsrBox"
                  >
                    <h2>Make Your Itinerary</h2>
                    <p>
                      Find your travel destination, because we have covered all
                      regions in the world.
                    </p>
                    <span>1</span>
                  </div>
                  <div
                    className="OsrBox"
                  >
                    <h2>Fair Pricing</h2>
                    <p>
                      After you find the trip you want to go to, you can
                      directly order ticket.
                    </p>
                    <span>2</span>
                  </div>
                  <div
                    className="OsrBox"
                  >
                    <h2>Pay and Go to destination</h2>
                    <p>
                      After you pay, you can immediatly enjoy the trip to the
                      destination.
                    </p>
                    <span>3</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="OurReachArea">
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h1>
                Our <span>Reach</span>
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore <br /> et dolore magna
                aliqua. Ut enim ad.
              </p>
            </div>
            <SwtMap />
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28036.837992602235!2d77.25942301083984!3d28.551597699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84369151d512cd45%3A0xa8b9685a307a3583!2sbookmymap!5e0!3m2!1sen!2sin!4v1699092191618!5m2!1sen!2sin"
              width="100%"
              height={600}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
             referrerPolicy="no-referrer-when-downgrade"
            /> */}
          </div>
        </section>
        {/* <section>
          <div className="ChoosePackageArea">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="ChoosePackageLeft"
                  data-aos="fade-up"
                  data-aos-duration="800"
                    
                >
                  <h1>
                    OUR <span>PRODUCT</span> OFFERINGS
                  </h1>
                  <h1>Choose the <span>right <br> package</span> with SWT</h1>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="ChoosePackageRight"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <p>
                    Beside our Do-It-Yourself Customized Packages, we also{" "}
                    <br /> have Series &amp; Ready Packages to make your Tour{" "}
                    <br /> Packages’ Designs and Offerings fast.
                  </p>
                </div>
              </div>
              <div 
              data-aos="zoom-in"
              data-aos-duration="800"
              >
              <div className="row"
              
              >
              <div className="col-md-4">
                <div
                  className="ChoosePackageBox animate fadeInUp"
                  
                >
                  <figure>
                    <img src={images.package_1} />
                    <span>Grand Tour of Europe - Group Tour </span>
                  </figure>
                  <h2>INR. 18,090</h2>
                  <span className="Center">Agent Login</span>
                  <ul>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Per PAX</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>13 Nights 14 Days</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Accommodation</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Sightseeings</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Indian Meals</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Tour Manager</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="ChoosePackageBox White"
                >
                  <figure>
                    <span>Recommended</span>
                    <img src={images.package_2} />
                    <span>Blissful Europe - Group Tour</span>
                  </figure>
                  <h2>INR. 21,060</h2>
                  <span className="Center">Agent Login</span>
                  <ul>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Per PAX</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>13 Nights 14 Days</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Accommodation</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Sightseeings</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Indian Meals</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Tour Manager</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="ChoosePackageBox"
                >
                  <figure>
                    <img src={images.package_3} />
                    <span>Blissful Europe - Group Tour</span>
                  </figure>
                  <h2>INR. 12,090</h2>
                  <span className="Center">Agent Login</span>
                  <ul>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Per PAX</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>13 Nights 14 Days</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Accommodation</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Sightseeings</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Indian Meals</span>
                    </li>
                    <li>
                      <img src={images.greenCheck} />
                      <span>Tour Manager</span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              </div>
              
              
            </div>
          </div>
        </section> */}
        <section>
          <div className="AdventureActivity">
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h1>
                Adventure &amp; <span>Activity</span>{" "}
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore <br /> et dolore magna
                aliqua. Ut enim ad.
              </p>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div
                  className="AdventureBox"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Sign Up</h6>
                  <p>
                    Agent will complete the registration process to become
                    member of SWT CLUB.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="AdventureBox animate fadeInUp"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Login</h6>
                  <p>Login access granted.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="AdventureBox"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Unlock Power</h6>
                  <p>Unlock thousands of Travel products available for you.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="AdventureBox"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Make Your Itinerary</h6>
                  <p>
                    Create your itinerary by selecting from the variety of
                    readymade itineraries available.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="AdventureBox"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Fair Pricing</h6>
                  <p>
                    All the travel products listed on portal have fair pricing
                    net to travel agent partners.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="AdventureBox"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Book Your Itinerary</h6>
                  <p>
                    Our Team will check and confirm availablity with shortest
                    revertal time.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="AdventureBox"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Online Payment</h6>
                  <p>
                    After receiving confirmation, Travel agent Partner can make
                    payment both online and offline.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="AdventureBox"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  <span className="Icon">
                    <img src={images.adventure_1} />
                  </span>
                  <h6>Booking Confirmed</h6>
                  <p>
                    Your Booking is confirmed and our operation team will assist
                    you with final handover.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="AdventureActivityLeft"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h1>
                    Our{" "}
                    <span>
                      News and <br />
                      insights
                    </span>{" "}
                    from experts
                  </h1>
                  {/* <h1>Choose the <span>right <br> package</span> with SWT</h1> */}
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="AdventureActivityRight"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur <br /> adipiscing
                    elit, sed do eiusmod tempor incididunt <br /> ut labore et
                    dolore magna aliqua. Ut enim ad.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="BestHotelDealsArea">
            <div className="row">
              <div className="col-md-6">
                <div className="BestHotelDealsLeft">
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Best Hotel <span>Deals</span>
                  </h1>
                  <div
                    className="BhdaBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h5>Surya Vilas, Solan</h5>
                    <p>
                      Experience an unforgettable romance on the heritage of
                      Solan.
                    </p>
                    <div className="BhdaDetails">
                      <p>
                        <span>
                          <img src={images.calendar} />
                        </span>
                        7 Days 6 Nights
                      </p>
                      <p>
                        <span>
                          <img src={images.star} />
                        </span>
                        4.7 (reviews)
                      </p>
                    </div>
                    <span className="BhdaBoxOverlay">Book Now</span>
                  </div>
                  <div
                    className="BhdaBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h5>Khas Bagh Amer, Jaipur, Rajasthan</h5>
                    <p>Give your family a fun-filled vacation in Rajasthan.</p>
                    <div className="BhdaDetails">
                      <p>
                        <span>
                          <img src={images.calendar} />
                        </span>
                        10 Days 9 Nights
                      </p>
                      <p>
                        <span>
                          <img src={images.star} />
                        </span>
                        4.7 (reviews)
                      </p>
                    </div>
                    <span className="BhdaBoxOverlay">Book Now</span>
                  </div>
                  <div
                    className="BhdaBox"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h5>Svasara Jungle Lodge, Jim Corbett</h5>
                    <p>
                      Enjoy thrilling outdoor adventures in the natural beauty
                      of Jim Corbett.
                    </p>
                    <div className="BhdaDetails">
                      <p>
                        <span>
                          <img src={images.calendar} />
                        </span>
                        5 Days 4 Nights
                      </p>
                      <p>
                        <span>
                          <img src={images.star} />
                        </span>
                        4.7 (reviews)
                      </p>
                    </div>
                    <span className="BhdaBoxOverlay">Book Now</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="BestHotelDealsRight"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <figure>
                    <img src={images.hotel} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="TestimonialsArea">
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span className="TestimonialSpan">Testimonial</span>
              <h1>
                Our <span>Empowered</span> Travel Agents
              </h1>
              <p>
                A good domain will clearly reflect your identity and purpose, be
                easy to remember, and can increase <br /> your visibility and
                branding.
              </p>
            </div>
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="TestimonialsBox">
                  <span className="quote">
                    <img src={images.quote} />
                  </span>
                  <hr />
                  <h5>
                    Inventore vel alias consequatur nihil maiores voluptate enim
                    ea deleniti.Inventore vel alias consequatur nihil maiores
                    voluptate enim ea deleniti.
                  </h5>
                  <div className="TestimonialsDetails">
                    <figure>
                      <img src={images.testimonial_1} />
                    </figure>
                    <h6>Lola Ross</h6>
                    <p>Digital Marketing Director</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="TestimonialsBox">
                  <span className="quote">
                    <img src={images.quote} />
                  </span>
                  <hr />
                  <h5>
                    Inventore vel alias consequatur nihil maiores voluptate enim
                    ea deleniti.Inventore vel alias consequatur nihil maiores
                    voluptate enim ea deleniti.
                  </h5>
                  <div className="TestimonialsDetails">
                    <figure>
                      <img src={images.testimonial_1} />
                    </figure>
                    <h6>Lola Ross</h6>
                    <p>Digital Marketing Director</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="TestimonialsBox">
                  <span className="quote">
                    <img src={images.quote} />
                  </span>
                  <hr />
                  <h5>
                    Inventore vel alias consequatur nihil maiores voluptate enim
                    ea deleniti.Inventore vel alias consequatur nihil maiores
                    voluptate enim ea deleniti.
                  </h5>
                  <div className="TestimonialsDetails">
                    <figure>
                      <img src={images.testimonial_1} />
                    </figure>
                    <h6>Lola Ross</h6>
                    <p>Digital Marketing Director</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="TestimonialsBox">
                  <span className="quote">
                    <img src={images.quote} />
                  </span>
                  <hr />
                  <h5>
                    Inventore vel alias consequatur nihil maiores voluptate enim
                    ea deleniti.Inventore vel alias consequatur nihil maiores
                    voluptate enim ea deleniti.
                  </h5>
                  <div className="TestimonialsDetails">
                    <figure>
                      <img src={images.testimonial_1} />
                    </figure>
                    <h6>Lola Ross</h6>
                    <p>Digital Marketing Director</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="TestimonialsBox">
                  <span className="quote">
                    <img src={images.quote} />
                  </span>
                  <hr />
                  <h5>
                    Inventore vel alias consequatur nihil maiores voluptate enim
                    ea deleniti.Inventore vel alias consequatur nihil maiores
                    voluptate enim ea deleniti.
                  </h5>
                  <div className="TestimonialsDetails">
                    <figure>
                      <img src={images.testimonial_1} />
                    </figure>
                    <h6>Lola Ross</h6>
                    <p>Digital Marketing Director</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="TestimonialsBox">
                  <span className="quote">
                    <img src={images.quote} />
                  </span>
                  <hr />
                  <h5>
                    Inventore vel alias consequatur nihil maiores voluptate enim
                    ea deleniti.Inventore vel alias consequatur nihil maiores
                    voluptate enim ea deleniti.
                  </h5>
                  <div className="TestimonialsDetails">
                    <figure>
                      <img src={images.testimonial_1} />
                    </figure>
                    <h6>Lola Ross</h6>
                    <p>Digital Marketing Director</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="OurPartnersArea"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              className="section-title"

            >
              <h1>
                Our <span>Partners</span>
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor.
              </p>
            </div>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="TrustedPartner"
            >
              <div className="CommonTabs">
                <Nav className="nav-tabs justify-content-center mb-5">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="TrustedPartner"
                    //  as={Link}
                    //   to="/payment-management/Payout"
                    >
                      {/* <img src={images.country_icon} /> */}
                      {" "}
                      Trusted Partner
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="ProudMember"
                    //   as={Link}
                    //   to="/payment-management/Transactions"
                    >
                      {/* <img src={images.flightIcon} /> */}
                      {" "}
                      {" "}
                      Proud Member
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="TrustedPartner">
                  <div className="tab-content">
                    <div className="tab-pane fade show active">
                      <div className="tab-content-details" style={{ display: 'flex', justifyContent: "center", alignItems: "center", margin: '0 10px' }}>
                        {partners?.length > 0 ? partners.map((item) => {
                          return (
                            <figure>
                              <img src={item?.image} height="70px" width="100px" style={{ margin: ' 0 10px' }} />
                            </figure>
                          )
                        }) : ""}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="ProudMember">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" >
                      <div className="tab-content-details" style={{ display: 'flex', justifyContent: "center", alignItems: "center", margin: '0 10px' }}>
                        {proudPartner?.length > 0 ? proudPartner.map((item) => {
                          return (
                            <figure>
                              <img src={item?.image} height="70px" width="100px" style={{ margin: ' 0 10px' }} />
                            </figure>
                          )
                        }) : ""}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            {/* <div className="PartnersTabs">
              <ul
                className="nav nav-tabs justify-content-center animate fadeInUp"
                id="myTab"
                data-animate="fadeInUp"
                data-duration="1s"
                data-delay="0.4s"
              >
                <li className="nav-item">
                  <a
                    href="#TrustedPartner"
                    className="nav-link active"
                    data-toggle="tab"
                  >
                    Trusted Partner
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#ProudMember" className="nav-link" data-toggle="tab">
                    Proud Member
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="TrustedPartner">
                  <div className="tab-content-details" id="myTab">
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                  </div>
                  <div className="tab-content-details">
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                  </div>
                </div>
                <div className="tab-pane fade" id="ProudMember">
                  <div className="tab-content-details">
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                  </div>
                  <div className="tab-content-details">
                    <figure>
                      <img src={images.partner_6} />
                    </figure>
                    <figure>
                      <img src={images.partner_5} />
                    </figure>
                    <figure>
                      <img src={images.partner_4} />
                    </figure>
                    <figure>
                      <img src={images.partner_3} />
                    </figure>
                    <figure>
                      <img src={images.partner_2} />
                    </figure>
                    <figure>
                      <img src={images.partner_1} />
                    </figure>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </main>
      <Footer />
      <Modal
        className="ModalBox"
        show={showHotelModal}
      // onHide={false}
      >
        <Modal.Body>
          {/* <a className="CloseModal" >
            x
          </a> */}
          <div class="modal-content">
            <div class="TransferBox">
              <div class="TransferHeader">
                <h5>
                  <span>
                    <img src="images/Refresh.png" />{" "}
                  </span>
                  Searching for
                  <strong>Istanbul</strong>
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  onClick={closeHotelModal}
                >
                  x
                </button>
              </div>
              <div class="TransferBody">
                <a href="product.html">
                  <div class="SearchInfo">
                    <h6>
                      <strong>Destination</strong>
                      <span>Istanbul, Mississippi Terminal</span>
                    </h6>
                    <p>
                      <span>
                        <img src="images/Calender.png" />{" "}
                      </span>{" "}
                      21 August - 22 August
                    </p>
                    <p>
                      <span>
                        <img src="images/hotel-grey.png" />{" "}
                      </span>{" "}
                      21 August - 22 August
                    </p>
                    <p>
                      <span>
                        <img src="images/user.png" />{" "}
                      </span>{" "}
                      21 August - 22 August
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </div>
  );
};

export default Index;
