import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerF } from '@react-google-maps/api';

import markerImage from '../assests/images/swtIconWithPin.png'
import markerImage2 from '../assests/images/markerImage.png'

const containerStyle = {
  width: '100%',
  height: '600px'
};

const containerStyle2 = {
  width: '100%',
  height: '400px'
};

const SwtMap = (props) => {

  const { isLoaded } = useJsApiLoader({
    
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDNMhySx9Oxt1WD_ilDXOa7fPLcsE6I-sI', // Replace with your API key
  });

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker.position));
      map.fitBounds(bounds);
    }
  }, [map]);

  const lat =  28.704060;
  const lng = 77.102493;


//   const icon = {
//     url:markerImage, // url
//     scaledSize: new google.maps.Size(10, 10), // scaled size
//     // origin: new google.maps.Point(0,0), // origin
//     // anchor: new google.maps.Point(0, 0) // anchor
// };


  // const markers = [
  //   { id: 1, position: { lat: 38.8951, lng: -77.0364 }, title: 'Washington, D.C.', country: 'United States' },
  //   { id: 2, position: { lat: 51.5074, lng: -0.1278 }, title: 'London', country: 'United Kingdom' },
  //   { id: 3, position: { lat: 48.8566, lng: 2.3522 }, title: 'Paris', country: 'France' },
  //   { id: 4, position: { lat: 40.7128, lng: -74.0060 }, title: 'New York City', country: 'United States' },
  //   { id: 5, position: { lat: 35.6895, lng: 139.6917 }, title: 'Tokyo', country: 'Japan' },
  //   { id: 6, position: { lat: -34.6083, lng: -58.3712 }, title: 'Buenos Aires', country: 'Argentina' },
  //   { id: 7, position: { lat: -33.8688, lng: 151.2093 }, title: 'Sydney', country: 'Australia' },
  //   { id: 8, position: { lat: 55.7558, lng: 37.6176 }, title: 'Moscow', country: 'Russia' },
  //   { id: 9, position: { lat: 19.4326, lng: -99.1332 }, title: 'Mexico City', country: 'Mexico' },
  //   { id: 10, position: { lat: 40.4168, lng: -3.7038 }, title: 'Madrid', country: 'Spain' },
  //   { id: 11, position: { lat: 39.9042, lng: 116.4074 }, title: 'Beijing', country: 'China' },
  //   { id: 12, position: { lat: -6.2088, lng: 106.8456 }, title: 'Jakarta', country: 'Indonesia' },
  //   { id: 13, position: { lat: 55.6761, lng: 12.5683 }, title: 'Copenhagen', country: 'Denmark' },
  //   { id: 14, position: { lat: 41.9028, lng: 12.4964 }, title: 'Rome', country: 'Italy' },
  //   { id: 15, position: { lat: -22.9068, lng: -43.1729 }, title: 'Rio de Janeiro', country: 'Brazil' },
  // ];
  let markers = props?.lat?[{ id: 1, position: { lat: lat, lng: lng }, title: '', country: '' }]: [
    { id: 1, position: { lat: 38.8951, lng: -77.0364 }, title: 'Washington, D.C.', country: 'United States' },
    { id: 2, position: { lat: 51.5074, lng: -0.1278 }, title: 'London', country: 'United Kingdom' },
    { id: 3, position: { lat: 48.8566, lng: 2.3522 }, title: 'Paris', country: 'France' },
    { id: 4, position: { lat: 40.7128, lng: -74.0060 }, title: 'New York City', country: 'United States' },
    { id: 5, position: { lat: 35.6895, lng: 139.6917 }, title: 'Tokyo', country: 'Japan' },
    { id: 6, position: { lat: -34.6083, lng: -58.3712 }, title: 'Buenos Aires', country: 'Argentina' },
    { id: 7, position: { lat: -33.8688, lng: 151.2093 }, title: 'Sydney', country: 'Australia' },
    { id: 8, position: { lat: 55.7558, lng: 37.6176 }, title: 'Moscow', country: 'Russia' },
    { id: 9, position: { lat: 19.4326, lng: -99.1332 }, title: 'Mexico City', country: 'Mexico' },
    { id: 10, position: { lat: 40.4168, lng: -3.7038 }, title: 'Madrid', country: 'Spain' },
    { id: 11, position: { lat: 39.9042, lng: 116.4074 }, title: 'Beijing', country: 'China' },
    { id: 12, position: { lat: -6.2088, lng: 106.8456 }, title: 'Jakarta', country: 'Indonesia' },
    { id: 13, position: { lat: 55.6761, lng: 12.5683 }, title: 'Copenhagen', country: 'Denmark' },
    { id: 14, position: { lat: 41.9028, lng: 12.4964 }, title: 'Rome', country: 'Italy' },
    { id: 15, position: { lat: -22.9068, lng: -43.1729 }, title: 'Rio de Janeiro', country: 'Brazil' },
  ]

  
    const onLoad = React.useCallback(function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds({lat:props?.lat , lng:props?.lng});
      map.fitBounds(bounds);
      setMap(map)
    }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])




  return isLoaded && (

    props?.type =="details"?<GoogleMap
    mapContainerStyle={containerStyle2}
    center={{lat:props?.lat , lng:props?.lng}}
    zoom={20}
    onLoad={onLoad}
    onUnmount={onUnmount}
>
    <MarkerF position={{lat:props?.lat , lng:props?.lng}}  icon={markerImage}/>
</GoogleMap>:


    
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: lat, lng: lng }}
      zoom={16}
      onLoad={(map) => setMap(map)}
    >
      {markers.map((marker) => (
        <Marker
          key={marker.id}
          position={marker.position}
          title={marker.title}
          icon={markerImage}
        />
      ))}
    </GoogleMap>
  ) 
};

export default SwtMap;
