import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { images } from "../utils/images";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../reduxToolKit/slices/countryListApiSlice";
import { fetchStates } from "../reduxToolKit/slices/stateListApiSlice";
import { fetchCities } from "../reduxToolKit/slices/cityListApiSlice";
import { uploadImageFunction } from "../utils/uploadImage";
import { signUpApi } from "../reduxToolKit/slices/signUpApiSlice";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { signUpUpdateApi } from "../reduxToolKit/slices/SignUpUpdateApiSlice";
import { Link, useNavigate } from "react-router-dom";

const initialState = {
  companyName: "",
  companyAccountingName: "",
  firstName: "",
  middleName: "",
  lastName: "",
  emailId: "",
  designation: "",
  natureOfBusiness: "",
  preferredCurrency: "",
  preferredCountry: "",
  preferredState: "",
  preferredCity: "",
  address: "",
  siteUrl: "",
  mobileNumber: "",
  userName: "",
  password: "",
  reCheckpassword: "",
  identificationProof: "",
  identificationProofFrontImg: null,
  identificationProofBackImg: null,
  addressProof: "",
  addressProofFrontImg: null,
  addressProofBackImg: null,
  pincode: "",
  pancardNumber: "",
  pancardImg: null,
  termAndCondition: "",
  errors: {},
};


const SignUp = () => {

  const [iState, updateState] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countries = useSelector((state) => state.countries.countries);
  const states = useSelector((state) => state.states.states);
  const cities = useSelector((state) => state.cities.cities);

  const state = useLocation()
  console.log('state in sign up', state)



  useEffect(() => {
    updateState({
      ...iState,
      companyName: state?.state?.loginRes?.data?.companyName,
      companyAccountingName: state?.state?.loginRes?.data?.companyAccountingName,
      firstName: state?.state?.loginRes?.data?.firstName,
      middleName: state?.loginRes?.data?.middleName,
      lastName: state?.state?.loginRes?.data?.lastName,
      emailId: state?.state?.loginRes?.data?.email,
      designation: state?.state?.loginRes?.data?.designation,
      natureOfBusiness: "",
      preferredCurrency: state?.state?.loginRes?.data?.currency,
      preferredCountry: state?.state?.loginRes?.data?.country,
      preferredState: state?.state?.loginRes?.data?.state,
      preferredCity: state?.state?.loginRes?.data?.city,
      address: "",
      siteUrl: "",
      mobileNumber: state?.state?.loginRes?.data?.phoneNumber,
      userName: "",
      password: "",
      reCheckpassword: "",
      identificationProof: state?.state?.loginRes?.data?.identificationProof?.selectedType,
      identificationProofFrontImg: state?.state?.loginRes?.data?.identificationProof?.images?.[0],
      identificationProofBackImg: state?.state?.loginRes?.data?.identificationProof?.images?.[1],
      addressProof: state?.state?.loginRes?.data?.addressProof?.selectedType,
      addressProofFrontImg: state?.state?.loginRes?.data?.addressProof?.images?.[0],
      addressProofBackImg: state?.state?.loginRes?.data?.addressProof?.images?.[1],
      pincode: state?.state?.loginRes?.data?.pincode,
      pancardNumber: state?.state?.loginRes?.data?.panCardProof?.selectedType,
      pancardImg: state?.state?.loginRes?.data?.panCardProof?.images?.[0],
      termAndCondition: "",
    })
  }, [])

  const {
    companyName,
    companyAccountingName,
    firstName,
    middleName,
    lastName,
    emailId,
    designation,
    natureOfBusiness,
    preferredCurrency,
    preferredCountry,
    preferredState,
    preferredCity,
    address,
    siteUrl,
    mobileNumber,
    userName,
    password,
    identificationProof,
    identificationProofFrontImg,
    identificationProofBackImg,
    addressProof,
    addressProofFrontImg,
    addressProofBackImg,
    pincode,
    pancardNumber,
    pancardImg,
    termAndCondition,
    reCheckpassword,
    errors,
  } = iState;

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    if (preferredCountry) {
      dispatch(fetchStates(preferredCountry));
    }
  }, [preferredCountry]);

  useEffect(() => {
    if (preferredCountry && preferredState) {
      console.log("preferredState", preferredState);
      dispatch(fetchCities({ preferredCountry, preferredState }));
    }
  }, [preferredCountry, preferredState]);

  console.log("preferredState", preferredState);

  const BusinessType = [
    {
      id: 1,
      name: "Destination Management Company",
    },
    {
      id: 2,
      name: "Tour Operator",
    },
    {
      id: 3,
      name: "Wholesale Travel Company",
    },
    {
      id: 4,
      name: "Corparate",
    },
  ];

  const Currency = [
    "Australian Dollar",
    "British Pound",
    "Euro",
    "Indian Rupees",
    "U.S. Dollar",
  ];

  const identificationProofList = ["voter_Id", " passport"];

  const addressProofList = ["voter_Id", "passport"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };



  const handleChange2 = (e) => {
    const { name, value, checked } = e.target
    console.log('terms and condition', name, value, checked)
    if (checked) {
      updateState({
        ...iState,
        termAndCondition: true
      })
    } else {
      updateState({
        ...iState,
        termAndCondition: false
      })
    }
    console.log(iState)
  }

  window.Buffer = window.Buffer || require("buffer").Buffer;
  const onFileHandler = async (e) => {

    const file = e.target.files[0];
    const image = e.target.name;
    console.log("file", file, "image", image);
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        const uploadedImage = await uploadImageFunction(
          file,
          image,
          updateState,
          iState
        );
        console.log("Uploaded image", uploadedImage);
      } else {
        toast.error("Please upload in JPEG,PNG,JPG format Only", {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          imageLoading: false,
        });
      }
    }
  }
    ;
  const handleClose = (type) => {
    console.log(type)
    console.log('handle close function')
    // console.log('image',e.target.name)
    // Reset the 'templateFile' state to null
    updateState({ ...iState, [type]: null });
    // Clear the file input field by resetting its value
    const fileInput = document.querySelector(`#${type}`);
    if (fileInput) {
      console.log("aaaaaaaaaa", fileInput.value)
      fileInput.value = '';
    }
    // Clear the file input field by replacing it with a new input element
    // const fileInput = document.querySelector("#createFileInput");

    // if (fileInput) {
    //   const newFileInput = document.createElement('input');
    //   newFileInput.type = 'file';

    //   // Replace the old input element with the new one
    //   fileInput.parentNode.replaceChild(newFileInput, fileInput);
    // }
  };

  // console.log("identificationProofFrontImg", identificationProofFrontImg);
  console.log("iState", iState);

  let handleValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!companyName || !companyName.trim()) {
      error.companyNameError = " * Company name can't be empty";
      formIsValid = false;
    }

    if (!companyAccountingName || !companyAccountingName.trim()) {
      error.companyAccountingNameError = " * Company Accounting name can't be empty";
      formIsValid = false;
    }

    if (!firstName || !firstName.trim()) {
      error.firstNameError = " * First name can't be empty";
      formIsValid = false;
    }

    // if (!middleName || !middleName.trim()) {
    //   error.middleNameError = " * Middle name can't be empty";
    //   formIsValid = false;
    // }

    if (!lastName || !lastName.trim()) {
      error.lastNameError = " * Last name can't be empty";
      formIsValid = false;
    }

    if (!emailId || !emailId.trim()) {
      error.emailIdError = " * Email can't be empty";
      formIsValid = false;
    }

    if (!designation || !designation.trim()) {
      error.designationError = " * Designation can't be empty";
      formIsValid = false;
    }

    if (!natureOfBusiness || !natureOfBusiness.trim()) {
      error.natureOfBusinessError = " * Nature Of Business can't be empty";
      formIsValid = false;
    }

    if (!preferredCurrency || !preferredCurrency.trim()) {
      error.preferredCurrencyError = " * Preferred Currency can't be empty";
      formIsValid = false;
    }

    if (!preferredCountry || !preferredCountry.trim()) {
      error.preferredCountryError = " * Preferred Country can't be empty";
      formIsValid = false;
    }

    if (!preferredState || !preferredState.trim()) {
      error.preferredStateError = " * Preferred State can't be empty";
      formIsValid = false;
    }

    if (!preferredCity || !preferredCity.trim()) {
      error.preferredCityError = " * Preferred City can't be empty";
      formIsValid = false;
    }

    if (!address || !address.trim()) {
      error.addressError = " * Address can't be empty";
      formIsValid = false;
    }

    if (!siteUrl || !siteUrl.trim()) {
      error.siteUrlError = " * Site Url can't be empty";
      formIsValid = false;
    } else {
      const validUrl = (siteUrl) => {
        let url;
        try {
          url = new URL(siteUrl);
        } catch (_) {
          return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
      }

      if (!validUrl(siteUrl)) {  // Call the validUrl function with siteUrl as a parameter
        error.siteUrlError = " * Enter a valid URL";
        formIsValid = false;
      }
    }

    if (!mobileNumber) {
      //|| !mobileNumber.trim()
      error.mobileNumberError = " * Mobile Number can't be empty";
      formIsValid = false;
    }

    if (!userName || !userName.trim()) {
      error.userNameError = " * User Name can't be empty";
      formIsValid = false;
    }

    if (!password || !password.trim()) {
      error.passwordError = " * Password can't be empty";
      formIsValid = false;
    } else {
      const validPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
      if (!validPassword) {
        error.passwordError =
          ` 
        * At least 8 characters long and one capital letter, lowercase letter, special character, numeric character`;
        formIsValid = false;
      }
    }


    if (!reCheckpassword || !reCheckpassword.trim()) {
      error.reCheckpasswordError = " * Recheck Password can't be empty";
      formIsValid = false;
    }

    if (password !== reCheckpassword) {
      error.reCheckpasswordError = " * Password didn't match";
      formIsValid = false;
    }

    if (!identificationProof || !identificationProof.trim()) {
      error.identificationProofError = " * Identification Proof can't be empty";
      formIsValid = false;
    }

    if (!identificationProofFrontImg || !identificationProofFrontImg.trim()) {
      error.identificationProofFrontImgError = " * Identification Image Proof can't be empty";
      formIsValid = false;
    }

    if (!identificationProofBackImg || !identificationProofBackImg.trim()) {
      error.identificationProofBackImgError = " * Identification Image Proof can't be empty";
      formIsValid = false;
    }

    if (!addressProof || !addressProof.trim()) {
      error.addressProofError = " *  AddressProof can't be empty";
      formIsValid = false;
    }

    if (!addressProofFrontImg || !addressProofFrontImg.trim()) {
      error.addressProofFrontImgError = " *  Address Proof Image can't be empty";
      formIsValid = false;
    }

    if (!addressProofBackImg || !addressProofBackImg.trim()) {
      error.addressProofBackImgError = " *  Address Proof Image can't be empty";
      formIsValid = false;
    }

    if (!pincode) {
      //|| !pincode.trim()
      error.pincodeError = " *  Pincode can't be empty";
      formIsValid = false;
    }

    // if (!pancardNumber || !pancardNumber.trim()) {
    //   error.pancardNumberError = " *  Pancard Number can't be empty";
    //   formIsValid = false;
    // }

    if (!pancardImg || !pancardImg.trim()) {
      error.pancardImgError = " *  Pancard Image can't be empty";
      formIsValid = false;
    }

    if (!pancardNumber || !pancardNumber.trim()) {
      error.pancardNumberError = " *  Pancard Number can't be empty";
      formIsValid = false;
    }

    if (!termAndCondition) {
      error.termAndConditionError = " *  Term And Condition can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = handleValidation()
    console.log('validation', validate)
    console.log("Form submitted");
    const data = {
      companyName,
      userName,
      phoneNumber: mobileNumber,
      countryCode: "+91",
      companyAccountingName,
      email: emailId,
      password,
      address,
      firstName,
      middleName,
      lastName,
      designation,
      bussiness: natureOfBusiness,
      currency: preferredCurrency,
      country: preferredCountry,
      state: preferredState,
      city: preferredCity,
      pincode,
      identificationProof: {
        selectedType: identificationProof,
        images: [
          identificationProofFrontImg,
          identificationProofBackImg
        ],
      },
      addressProof: {
        selectedType: addressProof,
        images: [
          addressProofFrontImg,
          addressProofBackImg
        ],
      },
      panCardProof: {
        selectedType: pancardNumber,
        images: [
          pancardImg
        ],
      },
    }

    if (validate) {

      dispatch(signUpApi(data))
        .then((result) => {
        console.log("result---->",result);
          if (result?.payload?.status === 200) {
            updateState(initialState);
            toast.success('Signed up sucessfully', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            })
            navigate('/agentLogin')
          } else {
            toast.error(result?.payload?.response?.data?.message ??  "AN Error Occured", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            })
          }
        })
    

    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div>
      <Header />
      <section className="hero" id="hero">
        <video autoPlay="true" muted="true" loop="true" width="100%">
          <source src={images.bannerVideo} type="video/mp4" />
        </video>
        <div
          className="hero-content animate fadeInUp"
          data-animate="fadeInUp"
          data-duration="1.5s"
          data-delay="0.3s"
        >
          <h1>
            Book Travel Boldly With SWT CLUB <br />
            <span>A Trusted Global B2B travel platform</span>
          </h1>
        </div>
        {/* <Link to="/signup" className="SignUpBtn">
            Sign Up
          </Link> */}
      </section>
      <section>
        <div className="ContactFormArea ContactArea">
          <div className="container">
            <div className="ContactFormHead TitleBox">
              <h3>Agent Registration Details</h3>
              <p>
                Thank you for showing interest in SWT CLUB - ONLINE GLOBAL DMC.{" "}
                <br /> To further assist you, kindly fill following details.
              </p>
              <span>Company Details</span>
            </div>
            <div className="ContactFormBody">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your company name."
                        name="companyName"
                        value={companyName}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.companyNameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company_acounting_name"
                        name="companyAccountingName"
                        value={companyAccountingName}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.companyAccountingNameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your First Name"
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.firstNameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Middle Name "
                        name="middleName"
                        value={middleName}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.middleNameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Last Name"
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.lastNameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email"
                        name="emailId"
                        value={emailId}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.emailIdError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Designation"
                        name="designation"
                        value={designation}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.designationError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="natureOfBusiness"
                        value={natureOfBusiness}
                        onChange={handleChange}
                      >
                        <option>Select Nature Of Bussiness</option>
                        {BusinessType.map((item) => {
                          return <option value={item.name}>{item.name}</option>;
                        })}
                      </select>
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.natureOfBusinessError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="preferredCurrency"
                        value={preferredCurrency}
                        onChange={handleChange}
                      >
                        <option>Select Preferred Currency</option>
                        {Currency.map((item) => {
                          return <option value={item} selected={preferredCurrency === item}> {item} </option>;
                        })}
                      </select>
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.preferredCurrencyError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="preferredCountry"
                        value={preferredCountry}
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        {countries?.length > 0 &&
                          countries?.map((item, ind) => {
                            return (
                              <option key={ind} value={item.iso2} selected={preferredCountry === item.iso2}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.preferredCountryError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="preferredState"
                        value={preferredState}
                        onChange={handleChange}
                      >
                        <option>Select Preferred State</option>
                        {states?.length > 0
                          ? states?.map((item, ind) => {
                            return (
                              <option key={ind} value={item.iso2} selected={preferredState === item.iso2}>
                                {item.name}
                              </option>
                            );
                          })
                          : "No data found"}
                      </select>
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.preferredStateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="preferredCity"
                        value={preferredCity}
                        onChange={handleChange}
                      >
                        <option value="">Select Preferred City</option>
                        {cities?.length > 0
                          ? cities?.map((item, ind) => {
                            return (
                              <option key={ind} value={item.id} selected={preferredCity === item.id}>
                                {item.name}
                              </option>
                            );
                          })
                          : "No data found"}
                      </select>
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.preferredCityError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Your Pincode"
                        name="pincode"
                        value={pincode}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.pincodeError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Write Address"
                        name="address"
                        value={address}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.addressError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Site URL."
                        name="siteUrl"
                        value={siteUrl}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.siteUrlError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Write Mobile No."
                        name="mobileNumber"
                        value={mobileNumber}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.mobileNumberError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="User Name"
                        name="userName"
                        value={userName}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.userNameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />
                      <span className="EyesIcon2 New"
                        onClick={handleTogglePassword}
                      >
                        {
                          showPassword ?
                            (
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            ) : (
                              <i className="fa fa-eye-slash" aria-hidden="true"></i>
                            )}
                      </span>
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.passwordError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Confirm Password"
                        name="reCheckpassword"
                        value={reCheckpassword}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }} className="d-flex">
                        {errors?.reCheckpasswordError}
                      </span>
                    </div>
                  </div>
                  <div className="CommonForm">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Identification Proof</label>
                          <select
                            className="form-control"
                            name="identificationProof"
                            value={identificationProof}
                            onChange={handleChange}
                          >
                            <option>--Select--</option>
                            {addressProofList.length > 0
                              ? addressProofList.map((item) => {
                                return <option value={item}>{item}</option>;
                              })
                              : "No data found"}
                          </select>
                          <span style={{ color: "red" }} className="d-flex">
                            {errors?.identificationProofError}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Upload Front</label>
                          <div
                            className="UploadBox"
                          >
                            <div
                              className="Upload"
                            >
                              <i
                                className="fa fa-upload"
                              />{" "}
                              <span>Upload Front</span>
                              <input
                                type="file"
                                name="identificationProofFrontImg"
                                // value={identificationProofFrontImg||""}
                                id="identificationProofFrontImg"
                                onChange={onFileHandler}

                              />

                              <figure>
                                <img
                                  src={
                                    identificationProofFrontImg
                                      ? identificationProofFrontImg
                                      : "Upload Image"
                                  }
                                  alt=""
                                  height='131px'
                                  width='156px'
                                />
                              </figure>
                              <span style={{ color: "red" }} className="d-flex">
                                {errors?.identificationProofFrontImgError}
                              </span>
                              <a class="CloseModal"
                                onClick={() => handleClose('identificationProofFrontImg')}
                              >
                                ×
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Upload Back </label>
                          <div className="UploadBox">
                            <div className="Upload">
                              <i className="fa fa-upload" /> <span>Upload</span>
                              <input
                                type="file"
                                name="identificationProofBackImg"
                                // value={identificationProofBackImg}
                                id="identificationProofBackImg"
                                onChange={onFileHandler}
                              />

                              <figure>
                                <img
                                  src={
                                    identificationProofBackImg
                                      ? identificationProofBackImg
                                      : "Upload Image"
                                  }
                                  alt=""
                                // height='131px'
                                // width='156px'
                                />
                              </figure>
                              <span style={{ color: "red" }} className="d-flex">
                                {errors?.identificationProofBackImgError}
                              </span>
                              <a class="CloseModal"
                                onClick={() => handleClose('identificationProofBackImg')}
                              >
                                ×
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Address Proof</label>
                          <select
                            className="form-control"
                            name="addressProof"
                            value={addressProof}
                            onChange={handleChange}
                          >
                            <option>--Select--</option>
                            {identificationProofList.length > 0
                              ? identificationProofList.map((item) => {
                                return <option value={item}>{item}</option>;
                              })
                              : "No data found"}
                          </select>
                          <span style={{ color: "red" }} className="d-flex">
                            {errors?.addressProofError}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Upload front </label>
                          <div className="UploadBox">
                            <div className="Upload">
                              <i className="fa fa-upload" /> <span>Upload</span>
                              <input
                                type="file"
                                name="addressProofFrontImg"
                                id="addressProofFrontImg"
                                //value={addressProofFrontImg}
                                onChange={onFileHandler}
                              />

                              <figure>
                                <img
                                  src={
                                    addressProofFrontImg
                                      ? addressProofFrontImg
                                      : "Upload Image"
                                  }
                                  alt=""
                                // height='131px'
                                // width='156px'
                                />
                              </figure>
                              <span style={{ color: "red" }} className="d-flex">
                                {errors?.addressProofFrontImgError}
                              </span>
                              <a class="CloseModal"
                                onClick={() => handleClose('addressProofFrontImg')}
                              >
                                ×
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Upload Back </label>
                          <div className="UploadBox">
                            <div className="Upload">
                              <i className="fa fa-upload" /> <span>Upload</span>
                              <input
                                type="file"
                                name="addressProofBackImg"
                                id="addressProofBackImg"
                                //value={addressProofBackImg}
                                onChange={onFileHandler}
                              />

                              <figure>
                                <img
                                  src={
                                    addressProofBackImg
                                      ? addressProofBackImg
                                      : "Upload Image"
                                  }
                                  alt=""
                                // height='131px'
                                // width='156px'
                                />
                              </figure>
                              <span style={{ color: "red" }} className="d-flex">
                                {errors?.addressProofBackImgError}
                              </span>
                              <a class="CloseModal"
                                onClick={() => handleClose('addressProofBackImg')}
                              >
                                ×
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Pan Card Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please enter your pancard number"
                            name="pancardNumber"
                            value={pancardNumber}
                            onChange={handleChange}
                          />
                          <span style={{ color: "red" }} className="d-flex">
                            {errors?.pancardNumberError}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Upload front </label>
                          <div className="UploadBox">
                            <div className="Upload">
                              <i className="fa fa-upload" /> <span>Upload</span>
                              <input
                                type="file"
                                name="pancardImg"
                                // value={pancardNumber}
                                id="pancardImg"
                                onChange={onFileHandler}
                              />
                              <figure>
                                <img
                                  src={
                                    pancardImg
                                      ? pancardImg
                                      : "Upload Image"
                                  }
                                  alt=""
                                // height='131px'
                                // width='156px'
                                />
                              </figure>
                              <span style={{ color: "red" }} className="d-flex">
                                {errors?.pancardImgError}
                              </span>
                              <a class="CloseModal"
                                onClick={() => handleClose('pancardImg')}
                              >
                                ×
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="CheckBox">
                        By continuing, you agree to our Terms &amp; Conditions
                        and Privacy Policy
                        <input
                          type="checkbox"
                          name="termAndCondition"
                          value={termAndCondition}
                          onChange={handleChange2}
                          checked={termAndCondition}
                        />
                        <span style={{ color: "red" }} className="d-flex">
                          {errors?.termAndConditionError}
                        </span>
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default SignUp;
