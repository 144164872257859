import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { privateData } from '../../config/apiKey';
import { xSignature } from '../../config/x-signature';
import { isLoggedIn } from '../../utils/LoggedIn';

// const apiUrl = 'https://api.example.com/cities';

export const fetchHotelBookingCancellationInternal = createAsyncThunk('HotelBookingCancellationInternal/fetchHotelBookingCancellationInternal', async (payload) => {
  console.log('payload===>',payload)
  const response = await axios.post(`http://18.217.92.138:4200/api/v1/user/hotel/cancel-booking`,payload,{
    headers: {
      "Authorization": isLoggedIn("swtadmin")
    },
  });
  return response.data;
});