import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../utils/LoggedIn';

// Assuming isLoggedIn returns the correct authorization token
export const fetchpackagebookedList = createAsyncThunk('packagebookedList/fetchpackagebookedList', async (payload, thunkAPI) => {
    try {
        const token = isLoggedIn("swtadmin");
        const response = await axios.get(`http://18.217.92.138:4200/api/v1/user/package/booking-list?search=${payload.search}&startDate=${payload.startDate}&endDate=${payload.endDate}&page=${payload.page}`, {
            headers: {
                "Authorization": token
            },
        });
        return response.data;
    } catch (error) {
        // Propagate the error to Redux state
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


const initialState = {
    packagebooked: [],
    status: 'idle',
    error: null,
}

const packagebookedSlice = createSlice({
    name: 'bookedPackageList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchpackagebookedList.pending, (state) => {
                state.status = 'loading';
                state.error = null; // Reset error on pending
            })
            .addCase(fetchpackagebookedList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packagebooked = action.payload;
            })
            .addCase(fetchpackagebookedList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message : 'Something went wrong.';
            });
    },
});

export default packagebookedSlice.reducer;
