
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../utils/LoggedIn';


export const fetchLocationList = createAsyncThunk('locationList/fetchlocationList', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`http://18.217.92.138:4200/api/v1/user/hotel_list?search=${payload.data}`, {
        headers: {
            "Authorization": isLoggedIn("swtadmin")
        },
    });
    return response.data;
});

const initialState = {
    locationList: [],
    status: false,
    error: null,
}
const locationListSlice = createSlice({
    name: 'locationList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocationList.pending, (state) => {
                state.status = true;
                state.locationList =[]

            })
            .addCase(fetchLocationList.fulfilled, (state, action) => {
                state.status = false;
                state.locationList = action.payload;
            })
            .addCase(fetchLocationList.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            });
    },
});

export default locationListSlice.reducer;
