import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoggedIn } from "../../utils/LoggedIn";


export const signUpUpdateApi = createAsyncThunk(
    "signUpupdate/postsignUpupdate",
    async (payload) => {
      console.log("payload===>", payload);
      const response = await axios.put(
        "http://18.217.92.138:4200/api/v1/user/update-user",
        payload,
        {
          headers: {
            "Authorization": payload.token
            //isLoggedIn("adminLogin")
          },
        }
      );
      return response.data
        ;
    }
  );