// citySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { privateData } from "../../config/apiKey";
import { xSignature } from "../../config/x-signature";
import signature from "../../config/signature";

// const apiUrl = 'https://api.example.com/cities';

export const fetchHotelList = createAsyncThunk(
  "hotelList/fetchHotelList",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.post(
      `${privateData["proxy-url"]}/hotel-api/1.0/hotels`,//
      payload,
      {
        headers: {
          "Api-key": privateData["Api-key"],
          "X-Signature": signature()//signature,
        },
      }
    );
    return response.data
      ;
  }
);

const hotelListSlice = createSlice({
  name: "hotelList",
  initialState: {
    hotelLists: [],
    status: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelList.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchHotelList.fulfilled, (state, action) => {
        state.status = false;
        state.hotelLists = action.payload;
      })
      .addCase(fetchHotelList.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export default hotelListSlice.reducer;
